import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react"
import { ListViewCircle } from "@ds/icons/ListViewCircle.tsx"
import { useGraphQLQuery } from "@data/useGraphQL.ts"
import { reponsesRapidesQuery } from "@data/messagerie/reponsesRapidesQuery.ts"
import { usePreferences } from "@infra/preferences/usePreferences.tsx"
import { classNames } from "@ds/classNames"

export interface ReponseRapideProps {
  text: string
  setText: (text: string) => void
  className?: string
}

export const ReponsesRapides = ({ text, setText, className }: ReponseRapideProps) => {
  const { identifiantDuCabinet } = usePreferences()
  const { data: reponsesRapides } = useGraphQLQuery({
    document: reponsesRapidesQuery,
    variables: { identifiantCabinet: identifiantDuCabinet! },
    enabled: identifiantDuCabinet !== undefined,
  })

  if (!reponsesRapides?.data?.reponsesRapides?.length) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        data-testid="reponses-rapides"
        className={classNames(
          "flex items-center justify-center rounded bg-extra-light-grey hover:opacity-60 active:opacity-100",
          className,
        )}
      >
        <ListViewCircle className="text-h3 text-gray-400" />
      </MenuButton>
      <MenuItems
        anchor="bottom start"
        className="flex flex-col items-stretch rounded-lg bg-white p-3 shadow-drop-shadow"
      >
        {reponsesRapides.data.reponsesRapides.map((reponseRapide, index) => (
          <MenuItem key={"reponse-rapide-" + index}>
            <button
              className="rounded-lg p-2 text-left hover:bg-extra-light-grey"
              onClick={() => setText(text + reponseRapide.contenu)}
            >
              <div className="selected:text-dark-plum truncate">{reponseRapide.titre}</div>
            </button>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  )
}
