import { patientParIdentifiantQuery } from "@data/patient/patientParIdentifiantQuery"
import { useGraphQLQuery } from "@data/useGraphQL"
import { classNames } from "@ds/classNames"
import { headingsPlugin, listsPlugin, markdownShortcutPlugin, MDXEditor, MDXEditorMethods } from "@mdxeditor/editor"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

type ContextePatientProps = {
  identifiantProfil: string
  align?: "row" | "column"
}

export const ContextePatient = ({ identifiantProfil, align = "row" }: ContextePatientProps) => {
  const { t } = useTranslation()
  const { data } = useGraphQLQuery({
    document: patientParIdentifiantQuery,
    variables: { identifiant: identifiantProfil },
  })
  const contextePatient = data?.data?.patientParIdentifiant?.contexte

  const habitudesDeVieARemplir =
    contextePatient && contextePatient?.habitudesDeVie.length > 0
      ? contextePatient && contextePatient?.habitudesDeVie
      : [
          { nom: "Activité physique", picto: "🏋️‍♀️", valeur: "Non renseigné" }, // Solution temporaire avant de pouvoir modifier les habitudes de vie
          { nom: "Nutrition", picto: "🍏", valeur: "Non renseigné" },
          { nom: "Sommeil", picto: "😴️", valeur: "Non renseigné" },
          { nom: "️Santé mentale", picto: "🧠", valeur: "Non renseigné" },
        ]
  const [markdown, setMarkdown] = useState("")

  const readOnly = true
  const editorRef = useRef<MDXEditorMethods>(null)

  useEffect(() => {
    editorRef.current?.setMarkdown(data?.data?.patientParIdentifiant.contexte?.synthese?.contenu || "")
    setMarkdown(data?.data?.patientParIdentifiant.contexte?.synthese?.contenu || "")
  }, [data?.data?.patientParIdentifiant.contexte?.synthese?.contenu])

  return (
    <div
      data-testid="contexte-patient"
      className={classNames(
        "items my-6 flex justify-between gap-8 rounded-xl p-4 py-4 shadow-very-light-shadow hover:shadow-hover-drop-shadow",
        align === "row" ? "flex-row" : "flex-col",
      )}
    >
      <div className="flex-1">
        <div className="pl-2 text-h4">{"🏡 " + t("profilPatient")}</div>
        <div className="mt-4"></div>
        <MDXEditor
          readOnly={readOnly}
          ref={editorRef}
          markdown={markdown}
          placeholder={t("patientNAPasCompleteSonProfil")}
          plugins={[listsPlugin(), headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }), markdownShortcutPlugin()]}
          contentEditableClassName="prose outline-none max-w-none !p-0 font-sans text-p-tiny"
          className={classNames("flex-1 font-sans outline-none transition-opacity")}
        />
      </div>
      <div>
        <div className="text-h4">{t("habitudesDeVie")}</div>
        <div className="flex w-80 flex-col justify-between gap-2 pt-4">
          {habitudesDeVieARemplir.map((habitude) => (
            <div key={habitude.nom} className="flex w-80 items-center justify-between gap-4">
              <div className="text-p-tiny">{habitude.picto + " " + habitude.nom}</div>
              <div className="flex w-36 items-center rounded bg-extra-light-grey px-2 py-1 text-h6 text-dark-plum">
                {habitude.valeur}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
