import { SidePanel } from "@ds/layout/SidePanel"
import { contextePatientPanelRoute, preconsultationRoute } from "@infra/navigation/router"
import { useNavigate } from "@tanstack/react-router"
import { ContextePatient } from "../components/ContextePatient"

export const ContextePatientPanel = () => {
  const navigate = useNavigate()
  const { id } = preconsultationRoute.useParams()
  const { identifiantProfil } = contextePatientPanelRoute.useParams()
  const close = () => navigate({ to: preconsultationRoute.to, params: { id } })

  return (
    <SidePanel onClose={close}>
      <ContextePatient identifiantProfil={identifiantProfil} align={"column"} />
    </SidePanel>
  )
}
